<template>
    <div class="dayDiv" @mouseleave="hideTemp" @mouseenter="enterDay($event)" >
        <PlanningTooMuchRdvComponent 
            v-for="(rdvs, index) in rdvMerged" 
            :key="'tooMuch_'+index" 
            :rdvs="rdvs"
            class="toomuchRdv"
            />

        <PlanningRdv v-for='(appointement, indexRdv) in appointementClean' 
            v-show="type != 'INDISPO_CONCESSION'"
            :creneau="creneau" 
            :key="'appointement_'+indexRdv" 
            :infos="appointement" 
            :unavailables='unavailableArray'
            :style="rdvPosition(appointement)" 
            :ref="'appointement_'+indexRdv"
            :type="type"
            mode="real" 
            :multi="multi"
            :places="places"
            :appointementsArray="appointementClean"
            :class="multi ? 'rdv multi' : 'rdv'"
            :rdvIsEdited='rdvIsEdited'
            @mouseover.native="hideTemp"
            @editRdv="editRdv"
            @recordInfos="recordInfos"
            />

        <PlanningRdv v-if="displayRdvTemp && type!='INDISPO_CONCESSION' && !rdvIsEdited" 
            :creneau="creneau" 
            :infos="itemTemp"
            :type="type" 
            :unavailables='unavailableArray'
            :style="itemTempPosition" 
            
            :appointementsArray="appointementsArray"
            :multi="multi"
            mode="temp" 
            class="rdvTemp" 
            />

        <PlanningUnavailableSlot v-for='(slot,index) in unavailableSlots' :key="'unavailable_'+index" :ref="'unavailable_'+index"
            v-show="slot.type != 'delete'"
            :style="slotStyle(slot, index) "  
            :unavailableSlot="slot"
            :type="type"
            :unavailableIsLocked="unavailableIsLocked" 
            :class="settingUnavailableTemp ? ' no-pointer-event' : ''"
            @mouseover.native="hoverUnavailable('unavailable_'+index, slot)"
            @mouseout.native="cancelUnavailable('unavailable_'+index, slot)"
            @click.native="clickUnavailable($event, slot)"/>

        <PlanningUnavailableTempSlot 
            v-if="displayUnavailableTemp && unavailableItemTemp.start != 0 && unavailableItemTemp.end != 0 && type != 'DISPLAY_OPERATION_RDV'" 
            :unavailableItemTemp="unavailableItemTemp" 
            :type="type"
            :style="itemUnavailableTempPosition"
            @mousedown.native="startSetUnavailableTemp"
            @mousemove.native="downUnavailableTemp"
            @mouseup.prevent.native="setUnavailableTemp"
            @mouseout.native="outUnavailableTemp"
            />

        <PlanningCell 
            v-for='(creneaux, index) in creneauxArray' 
            :key="creneaux.id" 
            :creneaux="creneaux" 
            :dayText="dayText" 
            :ref="creneaux.ref" 
            :hasOperationHere="hasOperationHere" 
            :componentRef="creneaux.ref"
            :type="type"
            :class='index%2 ? "even" : ""'
            :settingUnavailableTemp="settingUnavailableTemp"
            @showTemp="displayTemp"
            @addItem='addItem'
            @showUnavailableTemp="showUnavailableTemp"
            @mouseover.native="overCellFunction($event, creneaux.start)"
            />
        
        <v-dialog v-model="userIndispoDialog" max-width="800">
            <v-card>
                <v-toolbar tile dark color="primary" class="bg-gradient">
                    <v-btn
                        depressed
                        color="white"
                        class="square mr-2"
                        text
                        @click.native="closeUserIndispoDialog"
                    >
                        <v-icon dark small>$prev</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{$t("userIndispoReccurenceDialogLabel")}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn depressed v-show="unavailableItemTemp.id || _.some(unavailableSlots, {start : unavailableItemTemp.start, end:unavailableItemTemp.end, date:unavailableItemTemp.date})" color="error" class="mr-3" @click="deleteIndispo($event, unavailableItemTemp)">
                        {{$t('eraseLabel')}}
                    </v-btn>
                     <v-btn depressed outlined color="white" class="mr-3" @click="closeUserIndispoDialog">
                        {{$t('cancelLabel')}}
                    </v-btn>
                    <v-btn depressed outlined color="white" @click="recordUserIndispo">
                        {{$t('recordRdvLabel')}}
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pa-4">
                    <p  class="mb-1">Créneau sélectionné : </p>
                    <p>{{unavailableItemTemp.start}} - {{unavailableItemTemp.end}} le {{this.parseUsDateToFr(unavailableItemTemp.date)}}</p>
                    <v-radio-group
                    v-model="unavailableItemTemp.type"
                    @change="changeType"
                    >
                        <template v-slot:label>
                            <div>Type de l'indisponibilité</div>
                        </template>
                        <v-radio
                            label="Ponctuelle"
                            value="ponctuel"
                        ></v-radio>
                        <v-radio
                            label="Hebdomadaire"
                            value="recurrence"
                        ></v-radio>
                        
                    </v-radio-group>
                    <!-- <div class="d-flex align-center" v-if="unavailableItemTemp.type=='recurrence' || unavailableItemTemp.type=='hebdomadaire'">
                        <p class="mr-3 mb-0">Toute les </p>
                        <v-select v-model="unavailableItemTemp.value" dense filled :items="recurrenceItems" hide-details label="Période de récurrence" :no-data-text="$t('no-data-text')">
                        </v-select>
                        
                    </div> -->
                    <v-alert border="left" text color="error" class="mt-3" v-if="userIndispoError != ''">{{$t(userIndispoError)}}</v-alert>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import PlanningCell from './PlanningCell'
import PlanningRdv from './PlanningRdv'
import PlanningUnavailableTempSlot from '@/components/planning/PlanningComponents/PlanningUnavailableTempSlot'
import PlanningUnavailableSlot from './PlanningUnavailableSlot'
import PlanningTooMuchRdvComponent from '@/components/planning/PlanningComponents/PlanningTooMuchRdvComponent'
import dateManipulation from '@/mixins/dateManipulation'
import planningMixins from '@/mixins/planningMixins'
import CalendarOrganizer from '@/plugins/CalendarOrganizer'
export default {
    name:"PlanningDay",
    props:["isSettingUnavailable", "dayText", "hasOperationHere", "creneau", "appointements", "isRdvDragging", 'date', "rdvId", "unavailableSlots", "unavailableIsLocked", "type", "multi", "places", "vendorSelected", "rdvIsEdited", "popup"],
    mixins:[dateManipulation, planningMixins],
    components: {
        PlanningCell,
        PlanningRdv,
        PlanningUnavailableSlot,
        PlanningUnavailableTempSlot,
        PlanningTooMuchRdvComponent
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
            userIndispoDialog: false,
            userIndispoDialogMode: "",
            userIndispoError : "",
            dragStartYPoint: 0,
            dragStartXPoint: 0,
            displayRdvTemp:false,
            recurrenceItems: [
                {
                    value: "1",
                    text: "semaines"
                },
                {
                    value: "2",
                    text: "deux semaines"
                },
                {
                    value: "3",
                    text: "trois semaines"
                },
                {
                    value: "4",
                    text: "quatres semaines"
                },
            ],
            settingUnavailableTemp:false,
            displayUnavailableTemp:false,
            unavailableDown:false,
            unavailableUp:false,
            unavailableItemTemp: {
                id:"",
                type:"ponctuel",
                date:"",
                source: "",
                value: "1",
                day:0,
                top:0,
                start:0,
                end:0,
            },
            itemTemp: {
                type:"ponctuel",
                date:"",
                source: "",
                value: "",
                day:0,
                top:0,
                start:0,
                end:0,
            },
            appointementClean: [],
            indexToExclude: [0],
            rdvIndexToExclude: [0],
            rdvMerged: []
        }
    },
    created() {
        //on set la date du rdv temporaire
        this.itemTemp.date = this.date
        this.setClassesIndispo()
        if(this.type == "DISPLAY_OPERATION_RDV") {
            let appointements = this.appointements.filter((e) => e.date_start.split(' ')[0] == this.date)
            this.appointementClean = CalendarOrganizer.run(appointements)  
        }
    },
    mounted() {
        this.setClassesIndispo()
        if(this.type == "DISPLAY_OPERATION_RDV") {
            let appointements = this.appointements.filter((e) => e.date_start.split(' ')[0] == this.date)
            this.appointementClean = CalendarOrganizer.run(appointements)  
        }  
    },
    watch: {
        unavailableSlots: {
            deep: true,
            // We have to move our method to a handler field
            handler() {
                this.setClassesIndispo()
            }
        },
        appointementsArray: {
            immediate: true,
            // We have to move our method to a handler field
            handler(val) {
                if(val.length > 0){
                    this.appointementClean = CalendarOrganizer.run(val, this.date)  
                }
               
            }
            
        },
    },
    computed: {
        //Array des créneaux non disponibles
        unavailableArray: function(){
            let array = [];
            if(this.unavailableSlots && this.unavailableSlots.length > 0 & this.unavailableIsLocked){
                array = array.concat(this.unavailableSlots)
            }
            return array
        },
        //filtre les rendez-vous pour créer un array des rdvs du jour
        appointementsArray: function(){
            if(this.type == "DISPLAY_OPERATION_RDV"){
                let appointements = this.appointements.filter((e) => e.date_start.split(' ')[0] == this.date)
                return appointements
            } 
            return []
        },
        //array de creneaux
        creneauxArray: function() {
            let array = [];
            for (let index = 0; index < 96; index++) {
                let date = this.dayText.split('/').join('_');
                let ref = "cell_"+date+"_"+index;
                const element = {id: index, start: (0 + (0.25 * index)), ref: ref};
                array.push(element)
            }
            return array
        }, 
        //position du rdv temporaire
        itemTempPosition: function(){
            return "top:"+this.itemTemp.top+"px;"
        },

        //position du rdv temporaire
        itemUnavailableTempPosition: function(){
            return "top:"+this.unavailableItemTemp.top+"px;"
        },
        //position du rdv temporaire
        unavailableTempPosition: function(){
            return "height:"+this.heightUnavailable+"px; top:"+this.itemTemp.top+"px;"
        }
    },
    methods: {
        editRdv(value){
            this.$emit('editRdv', value)
        },
        //fonction pour gérer le déplacement rapide de la souris
        overCellFunction: function(event, start){
                if(this.settingUnavailableTemp){
                    if(this.unavailableUp){
                        this.unavailableItemTemp.start = this.parseFloatToHour(start)
                        this.unavailableItemTemp.top = start * 15 * 4
                    }
                    if(this.unavailableDown){
                        this.unavailableItemTemp.end = this.parseFloatToHour(start + 0.25)
                    }
                }
            
        },
        //Fonction de lancement de setting d'indipo
        startSetUnavailableTemp: function(event){
            if(!this.settingUnavailableTemp){
                this.settingUnavailableTemp = true
                this.dragStartYPoint = event.screenY
            }
            
        },
        outUnavailableTemp: function(event){
            if(this.settingUnavailableTemp && event.screenY - this.dragStartYPoint > 0) { //sort par en bas
                this.unavailableDown = true
                if(this.parseHourToFloat(this.unavailableItemTemp.end) - this.parseHourToFloat(this.unavailableItemTemp.start) == 0.25){
                    this.unavailableUp = false
                }
                this.dragStartYPoint = event.screenY
            }
            if(this.settingUnavailableTemp && event.screenY - this.dragStartYPoint < 0) { //sort par en haut
                this.unavailableUp = true
                if(this.parseHourToFloat(this.unavailableItemTemp.end) - this.parseHourToFloat(this.unavailableItemTemp.start) == 0.25){
                   this.unavailableDown = false
                }
                this.dragStartYPoint = event.screenY
            }
        },
        //Lorsque l'on bouge la souris lors du setting d'une indispo
        downUnavailableTemp: function(event){            
            if(this.settingUnavailableTemp && this.unavailableDown && event.screenY - this.dragStartYPoint < -15){
                let test = this.parseHourToFloat(this.unavailableItemTemp.end) - 0.25
                this.unavailableItemTemp.end = this.parseFloatToHour(test)
                this.dragStartYPoint = this.dragStartYPoint - 15
            } 
            if(this.unavailableUp && this.unavailableUp && this.settingUnavailableTemp && event.screenY - this.dragStartYPoint > 15){
                let test = this.parseHourToFloat(this.unavailableItemTemp.start) + 0.25
                this.unavailableItemTemp.start = this.parseFloatToHour(test)
                this.unavailableItemTemp.top = test * 15 * 4
                this.dragStartYPoint = this.dragStartYPoint + 15
            } 
        },

        //Au lacher du clic, on set l'indispo
        setUnavailableTemp: function(event) {
            if(this.settingUnavailableTemp){
                this.settingUnavailableTemp = false
                this.unavailableDown = false
                this.unavailableUp = false
                
                if(this.type == "INDISPO_CONCESSION" || this.type == 'OPERATION_HOURS'){
                    this.$emit('addItem', event, this.unavailableItemTemp)
                    //this.settingUnavailableTemp = false
                    this.unavailableItemTemp = {
                        type:"",
                        date:"",
                        source: "",
                        value: "1",
                        day:0,
                        top:0,
                        start:0,
                        end:0,
                    },
                    this.dragStartYPoint = 0
                    
                } else if(this.type == "INDISPO_USER" || this.type == "INDISPO_COACH"){
                    this.userIndispoDialogMode = "add"
                    this.userIndispoDialog=true
                }
            }
        },
        
        //Check si des indispo utilisateur de type différent se chevauchent pour les afficher divisées et gérer leur placement
        setClassesIndispo: function(){
            if(this.type == "INDISPO_USER"|| this.type == "INDISPO_COACH"){
                //création d'un array d'id pour les exclure au fur et a mesure du check
                let arrayIndex = []
                for (let index = 0; index < this.unavailableSlots.length; index++) {
                    arrayIndex.push(index)
                }

                let finalArray = []
                this.indexToExclude = [0];
                //tant qu'il y a des ids dans l'array d'id
                while (arrayIndex.length > 0) { 
                    //on exclu un id de plus
                    this.indexToExclude.push(arrayIndex[0])
                    // méthode récursive pour recupérer un array des ids qui s'overlapent
                    let arrayOverlap = this.getOverlapping(this.unavailableSlots[arrayIndex[0]], this.unavailableSlots)
                    arrayOverlap.push(arrayIndex[0])
                    finalArray.push(arrayOverlap)  
                    //on enleve les ids qui s'overlap de l'array d'index
                    arrayIndex = this._.difference(arrayIndex, this.indexToExclude)
                }

                //une fois les array d'ids qui se chevauchent fais, on les parcourent pour leur attribuer le bon style
                finalArray.forEach((arraymulti) => {
                    if(arraymulti.length > 1){
                        arraymulti.forEach((element, index) => {
                            setTimeout(() => {
                                let ref = this.$refs["unavailable_"+element]
                                ref[0].$el.style.width = "calc(90% / "+arraymulti.length+")"
                                ref[0].$el.style.left = "calc( "+index+" * calc(90% / "+arraymulti.length+"))"
                            }, 100);
                        })  
                    }
                })
            } else if((this.type == "PLANNING" || this.type =="DISPLAY_OPERATION_RDV") && this.multi){
                this.appointementClean = CalendarOrganizer.run(this.appointementsArray)                
            }
        },
        //recursive pour faire des arrays d'indispo utilisateur 
        getOverlapping: function(slot, slotArray){
                let indexArrayBis = []
                slotArray.forEach((e, index) => {
                    if(!this.indexToExclude.includes(index) && 
                    e.start < slot.end && e.end > slot.start &&
                    slot.source == e.source &&
                    (e.type != slot.type || (e.type == "recurrence" && slot.type == "recurrence" && e.value != slot.value))){
                        indexArrayBis.push(index)
                        this.indexToExclude.push(index)
                        indexArrayBis = indexArrayBis.concat(this.getOverlapping(slotArray[index], slotArray))
                    }
                })
                return indexArrayBis
        },

        deleteIndispo: function($event, slot){
            if(this.type == "INDISPO_USER"|| this.type == "INDISPO_COACH"){
               this.$emit('deleteIndispo', slot)
               this.closeUserIndispoDialog()
            }
        },

        recordUserIndispo(){
            if(this.unavailableItemTemp.type && this.unavailableItemTemp.type != ""){
                
                let itemToSend = this._.cloneDeep(this.unavailableItemTemp)
                itemToSend.source = "user"
                if(itemToSend.type == "ponctuel" || (itemToSend.type == "recurrence" && itemToSend.value != "")){
                    if(itemToSend.type == "recurrence" && itemToSend.value == "1"){
                        itemToSend.type = "hebdomadaire"
                    }
                    if(this.userIndispoDialogMode == "edit") {
                        this.$emit('editItem', event, itemToSend)
                    } else {
                        this.$emit('addItem', event, itemToSend)
                    }
                    
                    this.userIndispoDialogMode = ""
                    this.userIndispoDialog=false
                    this.settingUnavailableTemp = false
                    this.unavailableItemTemp = {
                        type:"",
                        date:"",
                        source: "",
                        value: "1",
                        day:0,
                        top:0,
                        start:0,
                        end:0,
                    },
                    this.dragStartYPoint = 0
                    this.userIndispoError = "";
                } else {
                    this.userIndispoError = "userRecurrencePeriodError"
                }
            } else {
                this.userIndispoError = "userRecurrenceTypeError"
            }    
        },
        //Au changement du type d'indispo utilisateur on réinitialise la valeur si le type n'est plus récurrent
        changeType(){
            if(this.itemTemp.type == "ponctuel"){
                this.itemTemp.value = ""
            }
        },
        //Au cancel de la modale de setting de l'indispo
        closeUserIndispoDialog(){
            this.settingUnavailableTemp = false
            this.unavailableItemTemp = {
                type:"",
                date:"",
                source: "",
                value: "1",
                day:0,
                top:0,
                start:0,
                end:0,
            },
            this.dragStartYPoint = 0
            this.userIndispoError = "";
            this.userIndispoDialog=false
        },
        //Methode pour gérer si la personne maintient son clic appuyé en changeant de jour
        enterDay(event){
            this.itemTemp.type = "" 
            if(this.isSettingUnavailable){
                let top = event.fromElement.offsetTop;
                let numericStart = (top / 15)*0.25
                this.itemTemp.start = this.parseFloatToHour(numericStart)
                this.itemTemp.day = new Date(this.date).getDay(),
                this.itemTemp.end = 0;
                
            }
        },

        //Methode d'affichage d'une indispo au survol 
        hoverUnavailable(ref, slot){
            if(!this.settingUnavailableTemp){
                this.hideTemp()
            }
            
            if(this.type == "INDISPO_CONCESSION" && slot.source =="entity"){
                this.$refs[ref][0].$el.classList.add('predelete')
            }
            if((this.type == "INDISPO_USER" || this.type == "INDISPO_COACH") && slot.source =="user"){
                this.$refs[ref][0].$el.classList.add('predelete')
            }
            if(this.type == "OPERATION_HOURS" && (slot.source == "operation" || slot.source == "entity")){
                this.$refs[ref][0].$el.classList.add('predelete')
            }
        },
        //Au clic d'une indispo
        clickUnavailable(event, slot){
            //Si indispo concession on supprime direct
            if(this.type == "INDISPO_CONCESSION"  || this.type == 'OPERATION_HOURS'){
                let item = {}
                item.start = slot.start
                item.end = slot.end
                item.day = new Date(this.date).getDay(),
                item.day == 0 ? item.day = 7 : item.day = item.day
                this.$emit('removeUnavailable', event, item)
            } 
            //Si indispo user on ouvre l'edition
            if((this.type == "INDISPO_USER" || this.type == "INDISPO_COACH") && slot.source=="user"){
                this.unavailableItemTemp = this._.cloneDeep(slot)
                if(this.unavailableItemTemp.type == "hebdomadaire"){
                    this.unavailableItemTemp.type = "recurrence"
                }
                this.userIndispoDialogMode = "edit"
                this.userIndispoDialog=true
            } 
        },
        //au mouse out on enleve la classe qui prépare la suppression
        cancelUnavailable(ref, slot){
            if(this.type == "INDISPO_CONCESSION" && slot.source =="entity"){
                this.$refs[ref][0].$el.classList.remove('predelete')
            }

            if((this.type == "INDISPO_USER" || this.type == "INDISPO_COACH") && slot.source =="user"){
                this.$refs[ref][0].$el.classList.remove('predelete')
            }

            if(this.type == "OPERATION_HOURS" && (slot.source == "operation" || slot.source == "entity")){
                this.$refs[ref][0].$el.classList.remove('predelete')
            }
        },

//---------------------------------------------------------   RDV   ---------------------------------------------------------------
        recordInfos(infos){
            this.$emit('recordInfos', infos)
        },
        //event d'edition / ajout du rdv
        editAppointement: function(event, id){
            this.$emit('editAppointement', event, id)
        },
        //Ajout d'item
        addItem: function(event){
            if((this.$func.hasRight('lead/add') || this.$func.hasRight('lead/update')) && !this.rdvIsEdited){
                this.itemTemp.date = this.date
                if(this.type == "PLANNING" || this.type == "ADD_RDV_REQUEST" || this.type == "EDIT_RDV_REQUEST" || this.type == "EDIT_RDV_DATE_REQUEST" || this.type == "DISPLAY_OPERATION_RDV"){
                    if(this.vendorSelected != "all"){
                        this.itemTemp.ref_id = this.vendorSelected
                    } else {
                        this.itemTemp.ref_id = null
                    }
                }
                this.$emit('addItem', event, this.itemTemp)
            }
        },
        

        rdvPosition: function(rdv){
            let css = "";
            css += "top:"+this.parseHourToFloat(rdv.start)*15*4+"px;"
            css += " left: calc( "+rdv.col+" * calc( 90% / "+rdv.max_cols+"));"
            css += " width: calc( "+rdv.col_width+" * calc( 90% / "+rdv.max_cols+"))"
            return css
        },

        //Calcul de la position des rendez vous 
        itemPosition: function(rdv){
            let css = "";
            css += "top:"+this.parseHourToFloat(rdv.start)*15*4+"px;"
            return css
        },


        //style des créneaux indisponibles
        slotStyle: function(slot, index) {
            //get position de l'item
            let style = this.itemPosition(slot);
            //get height de l'item
            let numericStart = this.parseHourToFloat(slot.start)
            let numericEnd = this.parseHourToFloat(slot.end)
            if(numericEnd == 0){numericEnd = 24}
            let height = (numericEnd - numericStart) * 60
            if((this.type == "INDISPO_USER" || this.type == "INDISPO_COACH")){
                style += " width:90%; z-index:6;"
            }
            style += " height:"+height+"px;"
            return style
        },


        //Fonction pour savoir si on peut afficher le hover du rendez vous (evite que le rendez vous temp soit superposé avec un rendez vous déjà présent)
        rdvIsPossible(numericStart, numericEnd, numericCreneau){
            let response = true;
            this.unavailableArray.forEach(appointement => {
                let appointmentStart = this.parseHourToFloat(appointement.start)
                let appointmentEnd = this.parseHourToFloat(appointement.end)
                appointmentEnd == 0 ? appointmentEnd = 24 : appointmentEnd = appointmentEnd
                if((numericStart > (appointmentStart-numericCreneau) && numericStart < appointmentEnd) || (numericEnd > appointmentStart && numericEnd <= appointmentEnd)){
                   response = false;
                }
            });
            if(!this.multi){
                this.appointementsArray.forEach(appointement => {
                    let appointmentStart = this.parseHourToFloat(appointement.start)
                    let appointmentEnd = this.parseHourToFloat(appointement.end)
                    appointmentEnd == 0 ? appointmentEnd = 24 : appointmentEnd = appointmentEnd
                    if((numericStart > (appointmentStart-numericCreneau) && numericStart < appointmentEnd) || (numericEnd > appointmentStart && numericEnd <= appointmentEnd)){
                        response = false;
                    }
                });
            }
            return response
        },

        showUnavailableTemp(event, top){
            this.displayUnavailableTemp = true
            let numericStart = top / 15 / 4;
             // Ajout Dono pour le bug MAC à voir si ça pète pas côté Windows
            if((numericStart % 1) != 0 && numericStart.toString().split('.')[1].length > 2) {
                numericStart = (Math.floor(numericStart * 10) / 10);
            }
            

            if(this.type == "INDISPO_CONCESSION"){
                this.unavailableItemTemp.source = "entity",
                this.unavailableItemTemp.type = "hebdomadaire"
            }
            if(this.type == "INDISPO_USER" || this.type == "INDISPO_COACH"){
                this.unavailableItemTemp.source = "user"
                //this.itemTemp.type = "hebdomadaire"
            }
            if(this.type == 'OPERATION_HOURS'){
                this.unavailableItemTemp.source = "operation"
                this.unavailableItemTemp.type = "hebdomadaire"
            }
            if(!this.userIndispoDialog){
                if(this.type == "INDISPO_CONCESSION" || this.type == "INDISPO_USER" || this.type == "INDISPO_COACH" || this.type == 'OPERATION_HOURS'){
                    this.unavailableItemTemp.date = this.date
                    //this.unavailableItemTemp.day = new Date(this.date).getDay(),
                     this.unavailableItemTemp.day = (new Date(this.date).getDay() == 0) ? 7 : new Date(this.date).getDay()
                    this.unavailableItemTemp.top = top;
                    this.unavailableItemTemp.start = this.parseFloatToHour(numericStart)
                    this.unavailableItemTemp.end = this.parseFloatToHour(numericStart + 0.25)
                }
            }

            if(this.type == "INDISPO_COACH"){
                if(numericStart < 12){
                    this.unavailableItemTemp.start = this.parseFloatToHour(0)
                    this.unavailableItemTemp.end = this.parseFloatToHour(12)
                    this.unavailableItemTemp.top = 0;
                } else {
                    this.unavailableItemTemp.start = this.parseFloatToHour(12)
                    this.unavailableItemTemp.end = this.parseFloatToHour(24)
                    this.unavailableItemTemp.top = 720;
                }
            }
            
        },
        //affichage du rdvTemp
        displayTemp(event, top){
            if ((this.$func.hasRight('lead/add') || this.$func.hasRight('lead/update'))) {
                let numericStart = top / 15 / 4;
                // Ajout Dono pour le bug MAC à voir si ça pète pas côté Windows
                if((numericStart % 1) != 0 && numericStart.toString().split('.')[1].length > 2) {
                    numericStart = (Math.floor(numericStart * 10) / 10);
                }
                if(this.type!="INDISPO_CONCESSION" && this.type!="INDISPO_USER" && this.type != "INDISPO_COACH" && this.type != 'OPERATION_HOURS'){
                    let numericCreneau = this.creneau / 60;
                    let numericEnd = numericStart + numericCreneau
                    //si le drag est actif (décrochage la souris du rdv draggué) alors on déplace le rdv a l'endroit ou est la souris au lieu de mettre le rdv temporaire
                    if(this.isRdvDragging){
                        this.displayRdvTemp = false;
                        this.$emit('dragRdv', event, "moveTo", this.rdvId, numericStart, numericEnd, this.dayText)
                    }else {
                        //si l'endroit est possible alors on set les date du rdv temporaire et on l'affiche
                        if(this.rdvIsPossible(numericStart, numericEnd, numericCreneau)){
                            this.itemTemp.top = top;
                            this.itemTemp.start = this.parseFloatToHour(numericStart)
                            this.itemTemp.end = this.parseFloatToHour(numericEnd)
                            this.displayRdvTemp = true;
                        } else {
                            this.displayRdvTemp = false;
                        }
                    }
                } 
            }
        },
        //fonction pour cacher le rendez vous temporaire
        hideTemp() {
            if(this.type != "INDISPO_CONCESSION" && this.type != "INDISPO_USER" && this.type != "INDISPO_COACH" && this.type != 'OPERATION_HOURS'){
                this.displayRdvTemp = false;
            } else {
                //si la popup n'est pas ouverte on cache l'indispo temporaire
                if(!this.userIndispoDialog){
                    this.displayUnavailableTemp = false;
                }
                this.settingUnavailableTemp = false;
            }
        }
    }
}
</script>
<style lang="scss">
    .dayDiv {
        position:relative;
        cursor: pointer;
    }
    .rdv {
        position:absolute;
        width:100%;
        //z-index:10;
        &.multi {
            width:90%;
        }
    }
    .unavailableTemp {
        //width:100%;
        //opacity:0.5;
        pointer-events: all;
    }
    .rdvTemp {
        position:absolute;
        width:100%;
        background-color: #61a2e2;
        pointer-events: none;
        z-index:6;
    }
</style>